import { useContext, useState, useEffect } from 'react';
import {
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { BarrierContext } from '../context/BarrierContext';
// import { Switch } from "@/components/ui/switch"

export default function SelectWellDropdown() {
  const {
    handleStatus,
    isApplication
  } = useContext(BarrierContext);
  const [showDropdown, setShowDropdown] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  if(!isApplication) {
    return null;
  }

  return (
      <Flex justify='end' mt={3} gap={3}>
        <Button
          variant='solid'
          colorScheme='blue'
          size='sm'
          w='70px'
          onClick={() => handleStatus('Approved')}
        >
          Approve
        </Button>
        <Button
          variant='solid'
          colorScheme='red'
          size='sm'
          w='70px'
          onClick={() => handleStatus('Rejected')}
        >
          Reject
        </Button>
      </Flex>
  );
}
