import { useContext, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { BarrierContext } from "../context/BarrierContext";
import Popup from "reactjs-popup";
import ConfigItem from "./ConfigItem";
import { debounce } from "lodash";
import { ChevronLeftIcon, ChevronRightIcon, SmallCloseIcon } from "@chakra-ui/icons";

import { Flex, Input, InputGroup, IconButton, Tooltip, InputRightElement } from "@chakra-ui/react";

export default function ConfigList() {
  const { allConfigs } = useContext(BarrierContext);
  return (
    <>
      <Search />
      {allConfigs.data?.map((item) => (
        <ConfigItem item={item} key={item?.id} />
      ))}
      <Pagination allConfigs={allConfigs} />
    </>
  );
}

function Search() {
  const { handleGetAllConfigs } = useContext(BarrierContext);

  const { register, watch, reset } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const debouncedSearch = useCallback(
    debounce((value) => {
      handleGetAllConfigs({ Search: {
        name: value
      } });
      console.log("Debounced search value:", value);
    }, 500),
    [] // Empty dependency array since we don't want to recreate the debounced function
  );

  const searchValue = watch("search");

  useEffect(() => {
    debouncedSearch(searchValue);

    // Cleanup: cancel any pending debounced calls when component unmounts
    return () => debouncedSearch.cancel();
  }, [searchValue, debouncedSearch]);
  return (
    <div className="border-b border-gray-200 pb-2 mb-2">
      <Popup
        trigger={
          <Flex w="full">
            <InputGroup size="sm" borderRadius="5px">
              <Input
                placeholder="Select Configuration"
                borderRadius="5px"
                {...register("search")}
              />
              {searchValue && (
                <InputRightElement w="3rem">
                  <SmallCloseIcon
                    onClick={() => {
                      reset({ search: '' });
                      handleGetAllConfigs();
                    }}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Flex>
        }
      ></Popup>
    </div>
  );
}

function Pagination({ allConfigs }) {
  const { handleGetAllConfigs } = useContext(BarrierContext);
  return (
    <div
      className="flex justify-center px-4 py-3 bg-white
    border-t border-gray-200 sm:px-6 gap-2"
    >
      <Tooltip label="Previous" bg="gray.100" color="black" fontSize="xs">
        <IconButton
          variant="outline"
          size="sm"
          aria-label="Previous"
          icon={<ChevronLeftIcon />}
          onClick={() => {
            if (allConfigs.current === 1) return;
            handleGetAllConfigs({ Page: allConfigs.current - 1 });
          }}
          className="flex-none"
        />
      </Tooltip>
      <select
        className="px-2 py-1.5 text-sm border rounded-md"
        value={allConfigs.current}
        onChange={(e) => {
          handleGetAllConfigs({ Page: e.target.value });
        }}
      >
        {Array.from({ length: allConfigs.size }, (_, i) => i + 1).map(
          (value) => (
            <option key={value} value={value}>
              {value}
            </option>
          )
        )}
      </select>
      <Tooltip label="Next" bg="gray.100" color="black" fontSize="xs">
        <IconButton
          variant="outline"
          size="sm"
          aria-label="Next"
          icon={<ChevronRightIcon />}
          onClick={() => {
            if (allConfigs.current === allConfigs.size) return;
            console.log(allConfigs.current + 1);
            handleGetAllConfigs({ Page: allConfigs.current + 1 });
          }}
          className="flex-none"
        />
      </Tooltip>
    </div>
  );
}
