import { useContext, useRef, useState } from 'react';
import Navbar from './components/Navbar';
import {
  Flex,
  IconButton,
  HStack,
  Button,
  useColorModeValue,
  Stack,
  Image,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { BsPrinterFill } from 'react-icons/bs';
import { useReactToPrint } from 'react-to-print';
import { BarrierContext } from './context/BarrierContext';
import DiagramList from './components/ConfigList';
import _ from 'lodash';
import { PiFilePngFill } from 'react-icons/pi';
import { MdEdit } from 'react-icons/md';
import DesignPanel from './components/DesignPanel';
import SelectWellDropdown from './components/SelectWellDropdown';
import ConfigHistory from './components/ConfigHistory';
import DiagramForm from './components/DiagramForm';
import { annotationMenu } from './data/annotationMenu';
import { IoMdCheckmark } from 'react-icons/io';
import { exportComponentAsPNG } from 'react-component-export-image';
import CasingConfig from './components/CasingConfig';

function App() {
  const {
    config,
    setConfig,
    handleResetBarriers,
    component,
    setComponent,
    handleConfigHistory,
    setCdft,
    selectedAnno,
    setSelectedAnno,
    setAccordionIndex,
  } = useContext(BarrierContext);

  const componentRef = useRef();
  const buttonsBg = useColorModeValue('white', 'gray.800');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${config?.name} diagram`,
  });

  const switchComponent = (component) => {
    switch (component) {
      case 'casing configuration':
        return <CasingConfig />;
        break;
      case 'diagram':
        return <DiagramForm ref={componentRef} />;
        break;
      case 'config history':
        return <ConfigHistory ref={componentRef} />;
        break;
      default:
        return null;
    }
  };

  return (
    <Flex w='100vw' h='100vh'>
      {/* <Navbar /> */}
      {/* buttons start */}
      <Flex
        pos='absolute'
        top={0}
        left='0'
        w='full'
        borderBottomWidth='1px'
        h={12}
        align='center'
        zIndex={998}
        bg={buttonsBg}
        justify='space-between'
      >
        <Flex justify='space-between' align='center'>
          <Flex w='360px'>
            <Flex w='full' align='center' justify='space-between'>
              <HStack spacing='16px' ml={2}>
                <Tooltip
                  label='Create new'
                  bg='gray.100'
                  color='black'
                  fontSize='xs'
                >
                  <IconButton
                    mr={6}
                    variant='outline'
                    colorScheme='blue'
                    size='sm'
                    icon={<AddIcon />}
                    onClick={() => {
                      setConfig(null);
                      setComponent('casing configuration');
                    }}
                  />
                </Tooltip>

                <Menu closeOnSelect={false}>
                  <Tooltip
                    label='Add annotation'
                    bg='gray.100'
                    color='black'
                    fontSize='xs'
                  >
                    <MenuButton className='flex justify-center items-center border p-[7px] rounded-md border-[#2b6bb0] hover:bg-sky-50'>
                      <MdEdit className='text-md text-[#2b6bb0]' />
                    </MenuButton>
                  </Tooltip>
                  <MenuList>
                    {annotationMenu?.map((item) => (
                      <MenuItem
                        padding={0}
                        h='24px'
                        w='130px'
                        key={item?.id}
                        onClick={() => {
                          setSelectedAnno(item.type);
                          setComponent('diagram');
                          setAccordionIndex(0);
                        }}
                      >
                        <div className='w-full flex items-center justify-start text-xs px-3 py-1 cursor-pointer '>
                          <div>
                            <Image src={item.imageURL} boxSize={4} mr={3} />
                          </div>
                          <div>
                            <p>{_.upperFirst(item.type)}</p>
                          </div>
                        </div>
                        {item.type === selectedAnno ? (
                          <IoMdCheckmark className='mr-2' />
                        ) : null}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>

                <Tooltip
                  label='Export as PNG'
                  bg='gray.100'
                  color='black'
                  fontSize='xs'
                >
                  <IconButton
                    variant='outline'
                    colorScheme='blue'
                    size='sm'
                    icon={<PiFilePngFill className='text-lg' />}
                    onClick={() => {
                      if (!component) return;
                      exportComponentAsPNG(componentRef, {
                        fileName: config?.name
                          ? `${config?.name} diagram`
                          : 'diagram',
                      });
                    }}
                  />
                </Tooltip>

                <Tooltip
                  label='Print'
                  bg='gray.100'
                  color='black'
                  fontSize='xs'
                >
                  <IconButton
                    variant='outline'
                    colorScheme='blue'
                    size='sm'
                    icon={<BsPrinterFill className='text-lg' />}
                    onClick={handlePrint}
                  />
                </Tooltip>
              </HStack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* buttons end */}

      {/* main start */}
      <Flex w='full' justify='space-between'>
        {/* left sidebar start */}
        <Flex
          minW='220px'
          h='100vh'
          borderRightWidth='1px'
          overflowY
          p={2}
          flexDir='column'
        >
          <div className='mt-[50px]'>
            <SelectWellDropdown />
          </div>

          <div className='overflow-y-auto w-full scrollbar-hide mt-3 mb-3'>
            <DiagramList />
            <Stack mt={6}>
              <Button size='sm' onClick={handleConfigHistory}>
                Configuration history
              </Button>
              <Button size='sm' onClick={handleResetBarriers}>
                Reset barriers
              </Button>
              <Button
                size='sm'
                onClick={() => {
                  setCdft(true);
                }}
              >
                Preview CDFT
              </Button>
              <Button size='sm' onClick={() => setCdft(false)}>
                Reset CDFT
              </Button>
            </Stack>
          </div>
        </Flex>
        {/* left sidebar end */}

        <Flex w='640px' h='100vh' overflowY>
          <div className='overflow-y-auto w-full scrollbar-hide mt-[108px] mb-3'>
            {switchComponent(component)}
          </div>
        </Flex>

        {/* right sidebar start */}
        <Flex
          w='220px'
          h='100vh'
          borderLeftWidth='1px'
          overflowY
          flexDir='column'
        >
          <DesignPanel />
        </Flex>
        {/* right sidebar end */}
      </Flex>

      {/* main end */}
    </Flex>
  );
}

export default App;
