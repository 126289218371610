import React, {
  useContext,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import Popup from "reactjs-popup";
import {
  Flex,
  IconButton,
  Input,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { BarrierContext } from "../context/BarrierContext";
import AnnotationList from "./AnnotationList";
import { useForm, useFieldArray } from "react-hook-form";
import _ from "lodash";
import { getRelevantAnnotation } from "../annotationUtils/getters";
import { TfiReload } from "react-icons/tfi";
import DraggableElementList from "./DraggableElementList";
import FourCasings from "./diagrams/FourCasings";
import ThreeCasings from "./diagrams/ThreeCasings";
import TwoCasings from "./diagrams/TwoCasings";
import {
  initialConfigFourCasings,
  initialConfigThreeCasings,
  initialConfigTwoCasings,
} from "../data/initialConfig";
import { Toggle } from "./ui/Toggle";

const DiagramForm = forwardRef((props, printRef) => {
  const {
    config,
    setConfig,
    handleSave,
    setComponent,
    setConfigSelected,
    configSelected,
    selectedAnno,
    setSelectedAnno,
    setAnno,
    setCdft,
    cdft,
    handleCdft,
  } = useContext(BarrierContext);

  const popupRef = useRef();

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      name: config?.name,
      elements: config?.elements,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "elements",
  });

  useEffect(() => {
    if (configSelected) {
      setValue("name", config?.name);
      setValue("elements", config?.elements);
      setConfigSelected(false);
    }
  }, [configSelected]);

  const handleAddAnno = (e) => {
    e.preventDefault();
    if (!selectedAnno) {
      setAnno(null);
      return;
    }

    const x = e.nativeEvent.offsetX - 10;
    const y = e.nativeEvent.offsetY - 10;

    if (selectedAnno) {
      const newAnnotation = getRelevantAnnotation(selectedAnno, x, y);
      setConfig((prev) => ({
        ...prev,
        annotations: [...config?.annotations, newAnnotation],
      }));
      setSelectedAnno(null);
      setAnno(newAnnotation);
    }
  };

  const allElements = watch("elements");

  useEffect(() => {
    setConfig((prev) => ({ ...prev, elements: allElements }));
  }, [allElements]);

  const watchCcvQty = watch("elements.5.quantity");
  const watchScvQty = watch("elements.6.quantity");
  const watchIcvQty = watch("elements.7.quantity");
  const watchPcvQty = watch("elements.8.quantity");
  const watchGlmQty = watch("elements.26.quantity");
  const watchSsdQty = watch("elements.27.quantity");
  const watchPackerQty = watch("elements.28.quantity");
  const watchPlugQty = watch("elements.29.quantity");
  const watchVrQty = watch("elements.31.quantity");

  // update form data

  const handleAddMultiple = () => {
    const ccv = config?.additionalElements
      .filter((item) => item.name === "conductor casing valves")
      .filter((item, index) => index < watchCcvQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const scv = config?.additionalElements
      .filter((item) => item.name === "surface casing valves")
      .filter((item, index) => index < watchScvQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const icv = config?.additionalElements
      .filter((item) => item.name === "intermediate casing valves")
      .filter((item, index) => index < watchIcvQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const pcv = config?.additionalElements
      .filter((item) => item.name === "production casing valves")
      .filter((item, index) => index < watchPcvQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const glm = config?.additionalElements
      .filter((item) => item.name === "gas lift mandrel")
      .filter((item, index) => index < watchGlmQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const ssd = config?.additionalElements
      .filter((item) => item.name === "sliding side door")
      .filter((item, index) => index < watchSsdQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const plug = config?.additionalElements
      .filter((item) => item.name === "tubing plug")
      .filter((item, index) => index < watchPlugQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const packer = config?.additionalElements
      .filter((item) => item.name === "production packer")
      .filter((item, index) => index < watchPackerQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const vr = config?.additionalElements
      .filter((item) => item.name === "vr plug")
      .filter((item, index) => index < watchVrQty - 1)
      .map((item) => ({ ...item, isVisible: true }));

    const merged = [].concat(ccv, scv, icv, pcv, glm, ssd, packer, plug, vr);

    const result = config?.additionalElements?.map((item1) => {
      const newItem = merged.find((item2) => item2.id === item1.id);
      return { ...item1, ...newItem };
    });

    return result;
  };

  useEffect(() => {
    setConfig((prev) => ({
      ...prev,
      additionalElements: handleAddMultiple(),
    }));
  }, [
    watchCcvQty,
    watchScvQty,
    watchIcvQty,
    watchPcvQty,
    watchGlmQty,
    watchSsdQty,
    watchPackerQty,
    watchPlugQty,
    watchVrQty,
  ]);

  const handleReset = () => {
    if (config?.casingConfig === "4 casings") {
      setConfig(initialConfigFourCasings);
    }
    if (config?.casingConfig === "3 casings") {
      setConfig(initialConfigThreeCasings);
    }
    if (config?.casingConfig === "2 casings") {
      setConfig(initialConfigTwoCasings);
    }

    setCdft(false);
    setComponent(null);
    setTimeout(() => setComponent("diagram"), 300);
  };

  const switchCasingConfig = (casingConfig) => {
    switch (casingConfig) {
      case "4 casings":
        return <FourCasings />;
      case "3 casings":
        return <ThreeCasings />;
      case "2 casings":
        return <TwoCasings />;
      default:
        return null;
    }
  };

  return (
    <form
      id="diagramForm"
      onSubmit={handleSubmit(handleSave)}
      autoComplete="off"
    >
      <Flex w="640px" flexDir="column">
        <Flex w="640px" justify="space-between" overflowY zIndex={40} mb={3}>
          <Button
            variant="solid"
            colorScheme="blue"
            size="sm"
            type="submit"
            form="diagramForm"
            w="80px"
          >
            Save
          </Button>
          <div className="flex space-x-3">
            <Tooltip label="Preview CDFT" bg="gray.100" color="black" fontSize="xs">
              <Toggle
                checked={cdft}
                onChange={() => {
                  setCdft(!cdft);
                  handleCdft(cdft);
                }}
              >Preview CDFT</Toggle>
            </Tooltip>
            <Tooltip label="Reset" bg="gray.100" color="black" fontSize="xs">
              <IconButton
                variant="outline"
                size="sm"
                aria-label="Reset"
                icon={<TfiReload />}
                onClick={handleReset}
                className="icon-refresh rotating"
              />
            </Tooltip>
            <Tooltip label="Close" bg="gray.100" color="black" fontSize="xs">
              <IconButton
                variant="outline"
                size="sm"
                aria-label="Close diagram"
                icon={<CloseIcon fontSize="10px" />}
                onClick={() => {
                  setConfig(null);
                  setCdft(false);
                  setComponent(null);
                }}
              />
            </Tooltip>
          </div>
        </Flex>
      </Flex>

      <div ref={printRef} id="diagram">
        <div className="flex flex-col w-full h-[1040px] border overflow-y-auto scrollbar-hide">
          <div className="p-2 border-b">
            <Input
              placeholder="Diagram Name"
              variant="unstyled"
              textAlign="center"
              className="font-bold"
              {...register("name", {
                required: true,
                onChange: (e) =>
                  setConfig((prev) => ({ ...prev, name: e.target.value })),
              })}
            />
          </div>
          <div
            className="flex w-full h-full"
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            {/* diagram container */}
            <div
              className="grid grid-cols-2 gap-2 mx-3 my-3 w-full h-full"
              onClick={handleAddAnno}
            >
              {/* diagram here */}
              <div className="relative snapContainer">
                {switchCasingConfig(config?.casingConfig)}
                <DraggableElementList />
                <AnnotationList />
              </div>

              <div>
                <div className="grid grid-cols-12 border h-5 text-[8px]">
                  <div className="flex items-center justify-center col-span-1 border-r text-[8px] font-bold uppercase">
                    No.
                  </div>
                  <div className="flex items-center col-span-7 border-r text-[8px] ml-1 font-bold uppercase">
                    Well Barrier Element
                  </div>
                  <div className="flex w-full h-full justify-center items-center col-span-3 border-r text-[8px] font-bold uppercase">
                    Barrier
                  </div>
                  <div className="flex items-center justify-center col-span-1 text-[8px] font-bold uppercase">
                    Qty
                  </div>
                </div>
                {allElements
                  ?.filter((item) => !item.isAdditional)
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className="grid grid-cols-12 border-b border-l border-r h-5 hover:bg-slate-100 text-[8px]"
                    >
                      <div className="flex items-center justify-center col-span-1 border-r text-[8px]">
                        {index + 1}
                      </div>
                      <div className="flex items-center col-span-7 border-r text-[8px]">
                        {/* <span className='ml-1'>{capitalizeFirst(item.name)}</span> */}
                        <span className="ml-1">{item.name.toUpperCase()}</span>
                      </div>

                      <Popup
                        ref={popupRef}
                        position="bottom center"
                        trigger={
                          item.barrier === "primary" ? (
                            <div className="text-[8px] flex w-full h-full justify-center items-center col-span-3 border-r  cursor-pointer border-l-4 border-l-[#0000ff]">
                              {item.barrier.toUpperCase()}
                            </div>
                          ) : item.barrier === "secondary" ? (
                            <div className="text-[8px]  flex w-full h-full justify-center items-center col-span-3 border-r  cursor-pointer border-l-4 border-l-[#ff0000]">
                              {item.barrier.toUpperCase()}
                            </div>
                          ) : item.barrier === "none" ? (
                            <div className="text-[8px] flex w-full h-full justify-center items-center col-span-3 border-r  cursor-pointer ">
                              {item.barrier.toUpperCase()}
                            </div>
                          ) : (
                            "none"
                          )
                        }
                      >
                        {/* onChange={closePopup} */}
                        <div className="bg-slate-100 w-[100px] text-[10px] p-2 -ml-[1px] -mt-[7px] ">
                          <RadioGroup>
                            <Stack direction="column" spacing="1">
                              <Radio
                                value="primary"
                                colorScheme="blue"
                                size="sm"
                                {...register(`elements.${index}.barrier`)}
                              >
                                <Text className="uppercase text-[8px]">
                                  Primary
                                </Text>
                              </Radio>

                              <Radio
                                value="secondary"
                                colorScheme="red"
                                size="sm"
                                {...register(`elements.${index}.barrier`)}
                              >
                                <Text className="uppercase text-[8px]">
                                  Secondary
                                </Text>
                              </Radio>
                              <Radio
                                value="none"
                                colorScheme="gray"
                                size="sm"
                                {...register(`elements.${index}.barrier`, {
                                  onChange: (e) => {
                                    if (e.target.value === "none") {
                                      setValue(`elements.${index}.quantity`, 0);
                                      setValue(
                                        `elements.${index}.barrier`,
                                        "none"
                                      );
                                    }
                                  },
                                })}
                                onChange={(e) => {
                                  if (e.target.value === "none") {
                                    setValue(
                                      `elements.${index}.barrier`,
                                      "none"
                                    );
                                  }
                                }}
                              >
                                <Text className="uppercase text-[8px]">
                                  None
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </div>
                      </Popup>

                      <div className="flex items-center justify-center col-span-1 text-[9px]">
                        <Input
                          textAlign="center"
                          size="9px"
                          variant="unstyled"
                          type="number"
                          {...register(`elements.${index}.quantity`, {
                            valueAsNumber: true,
                            onChange: (e) => {
                              if (e.target.value === "0") {
                                setValue(`elements.${index}.quantity`, 0);
                                // setValue(`elements.${index}.barrier`, 'none');
                              }
                            },
                          })}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* elements table */}
          </div>
        </div>
      </div>
    </form>
  );
});

export default DiagramForm;
