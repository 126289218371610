import { useContext } from 'react';
import { BarrierContext } from '../../context/BarrierContext';

export default function DiagramSVGSmall({}) {
  const { showCdft, setStroke } = useContext(BarrierContext);
  

  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 302 761'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='diagram'>
        <g id='xmas-tree'>
          <g id='tree-cap'>
            <rect
              id='Rectangle 1'
              x='119.047'
              y='1'
              width='42.2698'
              height='13.3133'
              rx='3'
              fill='white'
              stroke='black'
            />
            <g id='Group 36'>
              <path id='Vector 1' d='M124 1V14.1702' stroke='black' />
              <path id='Vector 2' d='M132.424 1V14.1702' stroke='black' />
              <path id='Vector 3' d='M140.424 1V14.1702' stroke='black' />
              <path id='Vector 4' d='M148.424 1V14.1702' stroke='black' />
              <path id='Vector 5' d='M156.424 1V14.1702' stroke='black' />
            </g>
          </g>
          <g id='cv'>
            <ellipse
              id='Ellipse 1'
              cx='13.9798'
              cy='13.901'
              rx='13.9798'
              ry='13.901'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 120.109 31.4146)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6'
              d='M130.254 21.7872L150.064 41.1988'
              stroke='#FF0000'
            />
            <path
              id='Vector 7'
              d='M130.198 41.3006L150.007 21.8899'
              stroke='#FF0000'
            />
          </g>
          <g id='ssv'>
            <ellipse
              id='Ellipse 1_2'
              cx='13.9798'
              cy='13.9436'
              rx='13.9798'
              ry='13.9436'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 120.109 94.1669)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_2'
              d='M130.254 84.54L149.812 104.198'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_2'
              d='M130.229 104.083L150.038 84.6724'
              stroke='#FF0000'
            />
          </g>
          <g id='lmv'>
            <ellipse
              id='Ellipse 1_3'
              cx='13.9798'
              cy='13.9798'
              rx='13.9798'
              ry='13.9798'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 120.109 132.128)'
              fill='white'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_3'
              d='M130.255 122.502L149.869 141.721'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_3'
              d='M130.255 142.07L150.064 122.66'
              stroke='#FF0000'
            />
          </g>
          <g id='wv'>
            <circle
              id='Ellipse 1_4'
              cx='13.9798'
              cy='13.9798'
              r='13.9798'
              transform='matrix(-0.714257 -0.699883 -0.714257 0.699883 131.244 60.8539)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_4'
              d='M121.097 51.2274L101.483 70.4464'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_4'
              d='M121.097 70.7959L101.288 51.3852'
              stroke='#FF0000'
            />
          </g>
          <g id='kwv'>
            <circle
              id='Ellipse 1_5'
              cx='14'
              cy='14'
              r='14'
              transform='matrix(-0.714257 -0.699883 -0.714257 0.699883 188.077 60.8539)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_5'
              d='M177.916 51.2135L158.274 70.4603'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_5'
              d='M177.916 70.8102L158.078 51.3715'
              stroke='#FF0000'
            />
          </g>
          <g id='wv-arrows'>
            <path
              id='Arrow 1'
              d='M72.6182 61.6457L77.6182 64.5324L77.6182 58.7589L72.6182 61.6457ZM89.7458 61.1457L77.1182 61.1457L77.1182 62.1457L89.7458 62.1457L89.7458 61.1457Z'
              fill='black'
            />
            <path
              id='Arrow 3'
              d='M72.6182 70.6591L77.6182 73.5459L77.6182 67.7724L72.6182 70.6591ZM89.7458 70.1591L77.1182 70.1591L77.1182 71.1591L89.7458 71.1591L89.7458 70.1591Z'
              fill='black'
            />
            <path
              id='Arrow 2'
              d='M72.6182 52.1315L77.6182 55.0182L77.6182 49.2447L72.6182 52.1315ZM89.7458 51.6315L77.1182 51.6315L77.1182 52.6315L89.7458 52.6315L89.7458 51.6315Z'
              fill='black'
            />
          </g>
          <g id='tree-body'>
            <g id='tree-body_2'>
              <path
                id='Vector 9'
                d='M125.558 46.5653V18.6754H108.076L97.5312 28.8387V46.5653H125.558Z'
                stroke='black'
              />
              <path
                id='Vector 29'
                d='M154.364 46.7085V18.8185H171.846L182.391 28.9818V46.7085H154.364Z'
                stroke='black'
              />
              <path
                id='Vector 10'
                d='M125.589 18.8545V14.1702'
                stroke='black'
              />
              <path
                id='Vector 30'
                d='M154.364 18.8545V14.1702'
                stroke='black'
              />
              <g id='kwv-flange'>
                <rect
                  id='Rectangle 3'
                  x='182.391'
                  y='41.2854'
                  width='7.54818'
                  height='39.4468'
                  stroke='black'
                />
                <rect
                  id='Rectangle 5'
                  x='189.939'
                  y='45.7232'
                  width='2.01285'
                  height='2.95851'
                  stroke='black'
                />
                <rect
                  id='Rectangle 6'
                  x='189.939'
                  y='73.8291'
                  width='2.01285'
                  height='2.95851'
                  stroke='black'
                />
                <rect
                  id='Rectangle 7'
                  x='189.939'
                  y='52.6264'
                  width='2.01285'
                  height='2.95851'
                  stroke='black'
                />
                <rect
                  id='Rectangle 8'
                  x='189.939'
                  y='60.0227'
                  width='2.01285'
                  height='2.95851'
                  stroke='black'
                />
                <rect
                  id='Rectangle 9'
                  x='189.939'
                  y='66.4327'
                  width='2.01285'
                  height='2.95851'
                  stroke='black'
                />
              </g>
              <path
                id='Vector 70'
                d='M97.9204 75.7604H72.6182'
                stroke='black'
              />
              <path
                id='Vector 71'
                d='M97.9204 46.7084H72.6182'
                stroke='black'
              />
            </g>
            <path
              id='Vector 68'
              d='M231.049 151.683V148.584L182.391 127.279V75.3731H154.364V113.334'
              stroke='black'
            />
            <path
              id='Vector 69'
              d='M49.2622 152.07V148.956L97.6735 127.543V75.3731H125.558V113.527'
              stroke='black'
            />
          </g>
        </g>
        <g id='tubing-hanger'>
          <g id='tubing-header-left'>
            <ellipse
              id='Ellipse 1_6'
              cx='6.98991'
              cy='6.95051'
              rx='6.98991'
              ry='6.95051'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 18.8994 151.783)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_6'
              d='M23.9719 146.97L33.8771 156.675'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_6'
              d='M23.9438 156.726L33.8485 147.021'
              stroke='#FF0000'
            />
          </g>
          <g id='tubing-header-right'>
            <ellipse
              id='Ellipse 1_7'
              cx='6.98991'
              cy='6.95051'
              rx='6.98991'
              ry='6.95051'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 242.338 151.783)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_7'
              d='M247.411 146.97L257.316 156.675'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_7'
              d='M247.383 156.726L257.287 147.021'
              stroke='#FF0000'
            />
          </g>
          <path id='Vector 41' d='M154.364 152.07H245.452' stroke='#FF0000' />
          <path id='Vector 42' d='M36.0271 152.07H125.558' stroke='#FF0000' />
        </g>
        <g id='production-tubing'>
          <path
            id='production-tubing-lower'
            d='M126 198.5H125.5V199V657V658.018L126.306 657.396L154.306 635.773L154.5 635.623V635.378V199V198.5H154H126Z'
            stroke='#0000FF'
          />
          <path
            id='production-tubing-upper'
            d='M126 112.5H125.5V113V199V199.5H126H154H154.5V199V113V112.5H154H126Z'
            stroke='#FF0000'
          />
        </g>
        <g id='production-casing'>
          <g id='production-casing-cement-left'>
            <g id='production-casing-cement'>
              <line
                id='Line 127'
                y1='-0.5'
                x2='24.163'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 93.6387 540.205)'
                stroke='#0000FF'
              />
              <line
                id='Line 128'
                y1='-0.5'
                x2='24.163'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 93.6387 557.249)'
                stroke='#0000FF'
              />
              <line
                id='Line 129'
                y1='-0.5'
                x2='26.3596'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 92.0815 575.842)'
                stroke='#0000FF'
              />
              <line
                id='Line 130'
                y1='-0.5'
                x2='23.0647'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 94.4172 590.562)'
                stroke='#0000FF'
              />
              <line
                id='Line 131'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 95.3521 606.675)'
                stroke='#0000FF'
              />
              <line
                id='Line 132'
                y1='-0.5'
                x2='20.868'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 95.9741 623.1)'
                stroke='#0000FF'
              />
              <line
                id='Line 133'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 96.7527 639.369)'
                stroke='#0000FF'
              />
              <line
                id='Line 134'
                y1='-0.5'
                x2='20.868'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 95.9741 657.188)'
                stroke='#0000FF'
              />
              <path
                id='Line 135'
                d='M98.8132 670.859L110.491 659.238'
                stroke='#0000FF'
              />
              <path
                id='Line 136'
                d='M95.699 692.05L103.095 684.69L110.491 677.33'
                stroke='#0000FF'
              />
              <path
                id='Line 137'
                d='M98.813 706.77L110.491 695.149'
                stroke='#0000FF'
              />
              <path
                id='Line 138'
                d='M100.095 724.862L110.491 714.517'
                stroke='#0000FF'
              />
              <path
                id='Line 139'
                d='M101.927 740.083L110.491 731.561'
                stroke='#0000FF'
              />
              <path
                id='Line 140'
                d='M103.484 754.802L110.491 747.83'
                stroke='#0000FF'
              />
            </g>
            <path
              id='production-casing-cement-curve'
              d='M88.5781 536.331L92.8601 539.818V545.241L90.9137 551.051L92.8601 554.15V559.96V568.869L90.9137 575.067L95.1956 580.878L92.8601 586.688L95.1956 593.273L92.8601 598.696L95.1956 604.507L92.8601 610.317L95.1956 615.74V623.487L98.699 630.072L95.1956 637.045L98.699 643.243V650.602L95.1956 656.025V662.223L98.699 668.421V678.492L95.1956 691.275L98.699 698.635V707.544L101.813 712.58L98.699 722.264L101.813 731.173V735.822V740.47V746.28V752.865L104.538 757.126L107.652 759.451'
              stroke='#0000FF'
            />
          </g>
          <g id='production-casing-cement-right'>
            <g id='production-casing-cement_2'>
              <line
                id='Line 127_2'
                y1='-0.5'
                x2='24.163'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 186.284 540.205)'
                stroke='#0000FF'
              />
              <line
                id='Line 128_2'
                y1='-0.5'
                x2='24.163'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 186.284 557.249)'
                stroke='#0000FF'
              />
              <line
                id='Line 129_2'
                y1='-0.5'
                x2='26.3596'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 187.841 575.842)'
                stroke='#0000FF'
              />
              <line
                id='Line 130_2'
                y1='-0.5'
                x2='23.0647'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 185.506 590.562)'
                stroke='#0000FF'
              />
              <line
                id='Line 131_2'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 184.571 606.675)'
                stroke='#0000FF'
              />
              <line
                id='Line 132_2'
                y1='-0.5'
                x2='20.868'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 183.949 623.1)'
                stroke='#0000FF'
              />
              <line
                id='Line 133_2'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 183.17 639.369)'
                stroke='#0000FF'
              />
              <line
                id='Line 134_2'
                y1='-0.5'
                x2='20.868'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 183.949 657.188)'
                stroke='#0000FF'
              />
              <path
                id='Line 135_2'
                d='M181.11 670.859L169.432 659.238'
                stroke='#0000FF'
              />
              <path
                id='Line 136_2'
                d='M184.224 692.05L176.828 684.69L169.432 677.33'
                stroke='#0000FF'
              />
              <path
                id='Line 137_2'
                d='M181.11 706.77L169.432 695.149'
                stroke='#0000FF'
              />
              <path
                id='Line 138_2'
                d='M179.828 724.862L169.432 714.517'
                stroke='#0000FF'
              />
              <path
                id='Line 139_2'
                d='M177.996 740.083L169.432 731.561'
                stroke='#0000FF'
              />
              <path
                id='Line 140_2'
                d='M176.438 754.802L169.432 747.83'
                stroke='#0000FF'
              />
            </g>
            <path
              id='production-casing-cement-curve_2'
              d='M191.345 536.331L187.063 539.818V545.241L189.009 551.051L187.063 554.15V559.96V568.869L189.009 575.067L184.727 580.878L187.063 586.688L184.727 593.273L187.063 598.696L184.727 604.507L187.063 610.317L184.727 615.74V623.487L181.224 630.072L184.727 637.045L181.224 643.243V650.602L184.727 656.025V662.223L181.224 668.421V678.492L184.727 691.275L181.224 698.635V707.544L178.11 712.58L181.224 722.264L178.11 731.173V735.822V740.47V746.28V752.865L175.385 757.126L172.271 759.451'
              stroke='#0000FF'
            />
            <g id='Group 27'>
              <rect
                id='Rectangle 24'
                width='4'
                height='16'
                transform='matrix(-1 0 0 1 234.667 165.015)'
                fill='black'
              />
            </g>
          </g>
          <g id='production-casing-lower'>
            <path
              id='production-casing-lower-left'
              d='M109.965 580.5L109.965 760'
              stroke='#0000FF'
            />
            <path
              id='production-casing-lower-right'
              d='M169 580L169 761'
              stroke='#0000FF'
            />
            <g id='production-casing-shoe'>
              <path
                id='production-casing-shoe_2'
                d='M110.766 756.352L110.766 761H101.424L110.766 756.352Z'
                fill='black'
              />
              <path
                id='production-casing-shoe_3'
                d='M169.156 756.352L169.156 761H178.499L169.156 756.352Z'
                fill='black'
              />
              <line
                id='Line 141'
                x1='110.766'
                y1='760.5'
                x2='169.935'
                y2='760.5'
                stroke='black'
              />
            </g>
          </g>
          <g id='production-casing-upper'>
            <g id='production-casing-above-packer-right'>
              <g id='Group 35'>
                <path
                  id='production-casing-upper_2'
                  d='M218.593 181.51L169 200V581'
                  stroke='#FF0000'
                />
              </g>
            </g>
            <g id='production-casing-above-packer-left'>
              <g id='Group 35_2'>
                <path
                  id='production-casing-upper_3'
                  d='M110 580.5L111.5 201.226L62.7189 181.51H30.9666'
                  stroke='#FF0000'
                />
                <path
                  id='Vector 67'
                  d='M49.2622 166.015H30.5774'
                  stroke='#FF0000'
                />
              </g>
            </g>
          </g>
          <g id='production-casing-valve-1'>
            <ellipse
              id='Ellipse 1_8'
              cx='10.8732'
              cy='10.8119'
              rx='10.8732'
              ry='10.8119'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 8 173.488)'
              fill='white'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_8'
              d='M15.8906 166L31.2986 181.098'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_8'
              d='M15.8469 181.177L31.2542 166.08'
              stroke='#FF0000'
            />
          </g>
          <g id='production-casing-seal'>
            <g id='production-casing-seal-left'>
              <g id='Group 6'>
                <rect
                  id='Rectangle 19'
                  x='49.3108'
                  y='152.07'
                  width='13.235'
                  height='13.945'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95'
                  d='M49.7002 165.764L62.546 152.07'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96'
                  d='M62.1566 165.628L49.5375 152.523'
                  stroke='#FF0000'
                />
              </g>
            </g>
            <g id='production-casing-seal-right'>
              <g id='Group 6_2'>
                <rect
                  id='Rectangle 19_2'
                  width='13.235'
                  height='13.945'
                  transform='matrix(-1 0 0 1 230.612 152.07)'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95_2'
                  d='M230.222 165.764L217.377 152.07'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96_2'
                  d='M217.766 165.628L230.385 152.523'
                  stroke='#FF0000'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='intermediate-casing'>
          <g id='intermediate-casing-left'>
            <g id='intermediate-casing-cement'>
              <line
                id='Line 127_3'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 80.4033 416.869)'
                stroke='#FF0000'
              />
              <line
                id='Line 128_3'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 80.4033 433.913)'
                stroke='#FF0000'
              />
              <line
                id='Line 129_3'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 80.4033 450.956)'
                stroke='#FF0000'
              />
              <line
                id='Line 130_3'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 80.4033 468)'
                stroke='#FF0000'
              />
              <line
                id='Line 131_3'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 80.4033 485.044)'
                stroke='#FF0000'
              />
              <line
                id='Line 132_3'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 81.804 500.694)'
                stroke='#FF0000'
              />
              <line
                id='Line 133_3'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 84.918 514.639)'
                stroke='#FF0000'
              />
              <line
                id='Line 134_3'
                y1='-0.5'
                x2='11.4325'
                y2='-0.5'
                transform='matrix(0.73539 -0.677644 0.681235 0.732065 87.4102 528.584)'
                stroke='#FF0000'
              />
            </g>
            <g id='intermediate-casing_2'>
              <path
                id='Vector 53'
                d='M49.2622 195.455L31.356 195.455'
                stroke='#FF0000'
              />
              <g id='intermediate-casing_3'>
                <g id='Group 15'>
                  <path
                    id='Vector 53_2'
                    d='M95.5848 535.557V224.119L62.4113 210.949H30.5774'
                    stroke='#FF0000'
                  />
                </g>
              </g>
            </g>
            <path
              id='intermediate-casing-shoe'
              d='M95.1954 530.908L95.1954 535.557H85.853L95.1954 530.908Z'
              fill='black'
            />
            <path
              id='intermediate-casing-cement-curve'
              d='M90.135 537.881L87.4101 535.557L84.6853 525.873L81.9604 520.45L84.6853 514.639L81.9604 506.117V498.37L78.457 494.496L80.0141 486.362L77.6785 478.227L82.3497 472.804L77.6785 464.282L80.0141 458.859L79.2355 453.049V450.725L74.9536 447.626'
              stroke='#FF0000'
            />
          </g>
          <g id='intermediate-casing-right'>
            <g id='intermediate-casing-cement_2'>
              <line
                id='Line 127_4'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 200.141 416.869)'
                stroke='#FF0000'
              />
              <line
                id='Line 128_4'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 200.141 433.913)'
                stroke='#FF0000'
              />
              <line
                id='Line 129_4'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 200.141 450.956)'
                stroke='#FF0000'
              />
              <line
                id='Line 130_4'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 200.141 468)'
                stroke='#FF0000'
              />
              <line
                id='Line 131_4'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 200.141 485.044)'
                stroke='#FF0000'
              />
              <line
                id='Line 132_4'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 198.74 500.694)'
                stroke='#FF0000'
              />
              <line
                id='Line 133_4'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 195.626 514.639)'
                stroke='#FF0000'
              />
              <line
                id='Line 134_4'
                y1='-0.5'
                x2='11.4325'
                y2='-0.5'
                transform='matrix(-0.73539 -0.677644 -0.681235 0.732065 193.134 528.584)'
                stroke='#FF0000'
              />
            </g>
            <g id='intermediate-casing_4'>
              <g id='intermediate-casing_5'>
                <g id='Group 15_2'>
                  <path
                    id='Vector 53_3'
                    d='M218.133 210.949L184.96 224.119V535.557'
                    stroke='#FF0000'
                  />
                </g>
              </g>
            </g>
            <path
              id='intermediate-casing-shoe_2'
              d='M185.349 530.908L185.349 535.557H194.691L185.349 530.908Z'
              fill='black'
            />
            <path
              id='intermediate-casing-cement-curve_2'
              d='M190.409 537.881L193.134 535.557L195.859 525.873L198.584 520.45L195.859 514.639L198.584 506.117V498.37L202.087 494.496L200.53 486.362L202.866 478.227L198.195 472.804L202.866 464.282L200.53 458.859L201.309 453.049V450.725L205.591 447.626'
              stroke='#FF0000'
            />
            <g id='Group 26'>
              <rect
                id='Rectangle 24_2'
                width='4'
                height='16'
                transform='matrix(-1 0 0 1 235.667 195.455)'
                fill='black'
              />
            </g>
          </g>
          <g id='intermediate-casing-valve-1'>
            <g id='Group 34'>
              <g id='production-casing-valve-1_2'>
                <ellipse
                  id='Ellipse 1_9'
                  cx='10.8732'
                  cy='10.8119'
                  rx='10.8732'
                  ry='10.8119'
                  transform='matrix(0.714257 -0.699883 0.714257 0.699883 8 202.927)'
                  stroke='#FF0000'
                />
                <path
                  id='Vector 6_9'
                  d='M15.8906 195.439L31.2986 210.537'
                  stroke='#FF0000'
                />
                <path
                  id='Vector 7_9'
                  d='M15.8469 210.617L31.2542 195.519'
                  stroke='#FF0000'
                />
              </g>
            </g>
          </g>
          <g id='intermediate-casing-seal'>
            <g id='intermediate-casing-seal-right'>
              <g id='Group 7'>
                <rect
                  id='Rectangle 19_3'
                  width='13.235'
                  height='13.945'
                  transform='matrix(-1 0 0 1 231.234 181.51)'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95_3'
                  d='M230.844 195.203L217.998 181.51'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96_3'
                  d='M218.388 195.067L231.007 181.962'
                  stroke='#FF0000'
                />
              </g>
            </g>
            <g id='intermediate-casing-seal-left'>
              <g id='Group 7_2'>
                <rect
                  id='Rectangle 19_4'
                  x='49.3108'
                  y='181.51'
                  width='13.235'
                  height='13.945'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95_4'
                  d='M49.7002 195.203L62.546 181.51'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96_4'
                  d='M62.1566 195.067L49.5375 181.962'
                  stroke='#FF0000'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='surface-casing'>
          <g id='surface-casing-left'>
            <g id='surface-casing_2'>
              <path
                id='Vector 57'
                d='M49.2622 224.894H31.356'
                stroke='#FF0000'
              />
              <path
                id='line'
                d='M80.0142 442.59L80.0142 240.388H31.356'
                stroke='#FF0000'
              />
            </g>
            <path
              id='surface-casing-shoe'
              d='M79.6249 437.942L79.6248 442.59H70.2825L79.6249 437.942Z'
              fill='black'
            />
            <g id='surface-casing-cement'>
              <line
                id='Line 125'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 262.7)'
                stroke='#FF0000'
              />
              <line
                id='Line 126'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 279.743)'
                stroke='#FF0000'
              />
              <line
                id='Line 127_5'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 296.787)'
                stroke='#FF0000'
              />
              <line
                id='Line 128_5'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 313.831)'
                stroke='#FF0000'
              />
              <line
                id='Line 129_5'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 330.875)'
                stroke='#FF0000'
              />
              <line
                id='Line 130_5'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 347.919)'
                stroke='#FF0000'
              />
              <line
                id='Line 131_5'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 64.8328 364.963)'
                stroke='#FF0000'
              />
              <line
                id='Line 132_5'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 66.2334 380.613)'
                stroke='#FF0000'
              />
              <line
                id='Line 133_5'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 69.3474 394.558)'
                stroke='#FF0000'
              />
              <line
                id='Line 134_5'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(0.70884 -0.705369 0.70884 0.705369 69.3474 411.601)'
                stroke='#FF0000'
              />
              <path
                id='Line 135_3'
                d='M69.5754 427.871L79.9716 417.525'
                stroke='#FF0000'
              />
            </g>
            <path
              id='surface-casing-cement-curve'
              d='M64.8328 375.964L66.0005 379.838L67.5575 384.486L67.5575 387.585L68.7253 391.459L68.7253 394.558L68.7253 398.431L67.5575 402.305L68.7253 406.178L68.7253 410.439L67.9468 415.088L69.1146 418.187L67.9468 420.511L69.1146 425.546L69.8931 427.871L69.1146 430.195L69.8931 433.681L69.1146 436.392L70.2824 439.879L70.2824 442.59L72.618 444.914L75.3429 447.626'
              stroke='#FF0000'
            />
          </g>
          <g id='surface-casing-right'>
            <g id='surface-casing_3'>
              <path
                id='line_2'
                d='M200.53 442.59L200.53 240.388L231.5 240.388'
                stroke='#FF0000'
              />
            </g>
            <path
              id='surface-casing-shoe_2'
              d='M200.92 437.942L200.92 442.59H210.262L200.92 437.942Z'
              fill='black'
            />
            <g id='surface-casing-cement_2'>
              <line
                id='Line 125_2'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 262.699)'
                stroke='#FF0000'
              />
              <line
                id='Line 126_2'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 279.743)'
                stroke='#FF0000'
              />
              <line
                id='Line 127_6'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 296.787)'
                stroke='#FF0000'
              />
              <line
                id='Line 128_6'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 313.831)'
                stroke='#FF0000'
              />
              <line
                id='Line 129_6'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 330.875)'
                stroke='#FF0000'
              />
              <line
                id='Line 130_6'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 347.919)'
                stroke='#FF0000'
              />
              <line
                id='Line 131_6'
                y1='-0.5'
                x2='21.7456'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 215.712 364.963)'
                stroke='#FF0000'
              />
              <line
                id='Line 132_6'
                y1='-0.5'
                x2='19.7697'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 214.311 380.613)'
                stroke='#FF0000'
              />
              <line
                id='Line 133_6'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 211.197 394.558)'
                stroke='#FF0000'
              />
              <line
                id='Line 134_6'
                y1='-0.5'
                x2='15.3765'
                y2='-0.5'
                transform='matrix(-0.70884 -0.705369 -0.70884 0.705369 211.197 411.601)'
                stroke='#FF0000'
              />
              <path
                id='Line 135_4'
                d='M210.969 427.871L200.573 417.525'
                stroke='#FF0000'
              />
            </g>
            <path
              id='surface-casing-cement-curve_2'
              d='M215.712 375.964L214.544 379.838L212.987 384.486L212.987 387.585L211.819 391.459L211.819 394.558L211.819 398.431L212.987 402.305L211.819 406.178L211.819 410.439L212.598 415.088L211.43 418.187L212.598 420.511L211.43 425.546L210.651 427.871L211.43 430.195L210.651 433.681L211.43 436.392L210.262 439.879L210.262 442.59L207.926 444.914L205.202 447.626'
              stroke='#FF0000'
            />
            <g id='Group 25'>
              <rect
                id='Rectangle 24_3'
                width='4'
                height='16'
                transform='matrix(-1 0 0 1 235.667 224.894)'
                fill='black'
              />
            </g>
          </g>
          <g id='surface-casing-valve-1'>
            <ellipse
              id='Ellipse 1_10'
              cx='10.8732'
              cy='10.8119'
              rx='10.8732'
              ry='10.8119'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 8 232.367)'
              stroke='#FF0000'
            />
            <path
              id='Vector 6_10'
              d='M15.8906 224.879L31.2986 239.977'
              stroke='#FF0000'
            />
            <path
              id='Vector 7_10'
              d='M15.8469 240.056L31.2542 224.959'
              stroke='#FF0000'
            />
          </g>
          <g id='surface-casing-seal'>
            <g id='surface-casing-seal-right'>
              <g id='Group 6_3'>
                <rect
                  id='Rectangle 19_5'
                  x='49.3108'
                  y='210.949'
                  width='13.235'
                  height='13.945'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95_5'
                  d='M49.7002 224.643L62.546 210.949'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96_5'
                  d='M62.1566 224.507L49.5375 211.401'
                  stroke='#FF0000'
                />
              </g>
            </g>
            <g id='surface-casing-seal-left'>
              <g id='Group 6_4'>
                <rect
                  id='Rectangle 19_6'
                  width='13.235'
                  height='13.945'
                  transform='matrix(-1 0 0 1 231.234 210.949)'
                  stroke='#FF0000'
                />
                <path
                  id='Line 95_6'
                  d='M230.844 224.643L217.998 210.949'
                  stroke='#FF0000'
                />
                <path
                  id='Line 96_6'
                  d='M218.388 224.507L231.007 211.401'
                  stroke='#FF0000'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='conductor-casing'>
          <g id='conductor-casing-left'>
            <g id='conductor-casing-seal'>
              <g id='Group 24'>
                <rect
                  id='Rectangle 24_4'
                  x='45.8125'
                  y='240.388'
                  width='4'
                  height='16'
                  fill='black'
                />
              </g>
            </g>
            <path
              id='conductor-casing_2'
              d='M50.0408 255.883H64.0543V376.352'
              stroke='#FF0000'
            />
            <path
              id='conductor-casing-shoe'
              d='M64.0543 372.091L64.0543 376.739H54.7119L64.0543 372.091Z'
              fill='black'
            />
          </g>
          <g id='conductor-casing-right'>
            <g id='conductor-casing-seal_2'>
              <g id='Group 24_2'>
                <rect
                  id='Rectangle 24_5'
                  width='4'
                  height='16'
                  transform='matrix(-1 0 0 1 235.667 240.388)'
                  fill='black'
                />
              </g>
            </g>
            <path
              id='conductor-casing_3'
              d='M231.439 255.883H216.647V375.964'
              stroke='#FF0000'
            />
            <path
              id='conductor-casing-shoe_2'
              d='M216.647 372.091L216.647 376.739H225.989L216.647 372.091Z'
              fill='black'
            />
          </g>
        </g>
        <g id='downhole-safety-valve'>
          <g id='dhsv'>
            <ellipse
              id='Ellipse 1_11'
              cx='13.9798'
              cy='13.9798'
              rx='13.9798'
              ry='13.9798'
              transform='matrix(0.714257 -0.699883 0.714257 0.699883 120.109 261.693)'
              stroke='#0000FF'
            />
            <path
              id='Vector 6_11'
              d='M130.255 252.067L149.869 271.286'
              stroke='#0000FF'
            />
            <path
              id='Vector 7_11'
              d='M130.255 271.635L150.064 252.224'
              stroke='#0000FF'
            />
          </g>
          <g id='downhole-safety-valve-control-line'>
            <path
              id='Vector 64'
              d='M220.428 111H161.168L161.168 262H154'
              stroke='#0000FF'
            />
            <ellipse
              id='Ellipse 12'
              cx='220.751'
              cy='111.324'
              rx='2.24855'
              ry='2.32416'
              fill='#0000FF'
            />
          </g>
        </g>
        <g id='svln-control-line'>
          <path id='Vector 65' d='M62 113H118.289V232H125' stroke='#FF0000' />
          <ellipse
            id='Ellipse 12_2'
            cx='60.3356'
            cy='113.324'
            rx='2.33559'
            ry='2.32416'
            fill='#FF0000'
          />
        </g>
        <g id='sea-level'>
          <g id='waves 3' clipPath='url(#clip0_282_3)'>
            <g id='boat 1' clipPath='url(#clip1_282_3)'>
              <g id='Group'>
                <path
                  id='Vector'
                  d='M8 281.695L10.2898 287.84H44.7682L45.8151 283.231C45.8151 283.231 17.6825 284.698 8 281.695Z'
                  fill='black'
                />
                <path
                  id='Vector_2'
                  d='M15.8508 284.069L17.9443 280.857L39.2071 280.927L41.3009 284.139L15.8508 284.069Z'
                  fill='black'
                />
                <path
                  id='Vector_3'
                  d='M34.0059 272.933C33.2537 272.933 33.1558 276.179 33.1558 276.179H34.8565C34.8565 276.179 34.7585 272.933 34.0059 272.933Z'
                  fill='black'
                />
                <path
                  id='Vector_4'
                  d='M25.5337 275.9L28.5756 270H29.0993L27.1199 273.875L27.7906 276.598L25.5337 275.9Z'
                  fill='black'
                />
                <path
                  id='Vector_5'
                  d='M25.272 278.832H35.4127L36.9174 280.997H24.0291L25.272 278.832ZM36.1323 275.9H25.5338L22.3931 281.207L40.493 282.899L36.1323 275.9Z'
                  fill='black'
                />
                <path
                  id='Vector_6'
                  d='M27.1157 278.427H26.3125V281.667H27.1157V278.427Z'
                  fill='black'
                />
                <path
                  id='Vector_7'
                  d='M29.6037 278.427H28.8005V281.667H29.6037V278.427Z'
                  fill='black'
                />
                <path
                  id='Vector_8'
                  d='M32.0915 278.427H31.2883V281.667H32.0915V278.427Z'
                  fill='black'
                />
                <path
                  id='Vector_9'
                  d='M34.5793 278.427H33.7761V281.667H34.5793V278.427Z'
                  fill='black'
                />
              </g>
            </g>
            <path
              id='waves-right'
              d='M2 287.5L3.01813 288.878C4.08891 290.327 6.30473 290.166 7.15394 288.576L7.36452 288.182C8.13772 286.735 10.1713 286.627 11.094 287.984V287.984C11.9341 289.219 13.7391 289.264 14.6398 288.072L14.9759 287.627C15.927 286.369 17.8484 286.468 18.6645 287.818V287.818C19.4579 289.131 21.3086 289.269 22.2889 288.09L22.8176 287.454C23.878 286.178 25.8363 286.178 26.8967 287.454L27.4904 288.168C28.4142 289.28 30.1706 289.097 30.8463 287.82V287.82C31.541 286.506 33.3649 286.359 34.2606 287.545L34.5419 287.917C35.5 289.185 37.4202 289.137 38.314 287.823L38.418 287.67C39.2894 286.389 41.1781 286.389 42.0495 287.67L42.167 287.843C43.0119 289.085 44.8591 289.036 45.6364 287.75V287.75C46.4136 286.464 48.2609 286.415 49.1057 287.657L49.2728 287.903C50.1399 289.178 52.0028 289.224 52.9324 287.994L53.1259 287.738C54.1171 286.426 56.1196 286.53 56.9702 287.937V287.937C57.7554 289.236 59.5545 289.446 60.6182 288.363L61.1299 287.842C62.5075 286.44 64.8527 286.791 65.7589 288.536L66 289'
              stroke='black'
            />
          </g>
          <g id='fish' clipPath='url(#clip2_282_3)'>
            <g id='Group_2'>
              <path
                id='Vector_10'
                d='M46.7138 323.683C45.5008 321.733 41.6411 319.41 38.1123 319.825C36.3479 318.373 35.429 318 35.429 318C35.429 318 33.9218 319.659 34.1792 321.152C34.8408 321.526 35.9804 321.028 35.9804 321.028C35.9804 321.028 34.3997 322.065 33.3338 321.941C32.2678 321.816 29.8048 318.332 29.3637 319.576C29.327 320.945 29.8416 322.438 29.5844 322.77C29.327 323.102 28.0772 324.678 29.2536 325.01C30.4298 325.342 32.4882 323.724 33.3338 324.139C34.1792 324.554 34.4732 324.844 34.4732 324.844C34.4732 324.844 33.3706 324.595 32.9661 324.554C32.5617 324.512 33.3338 329.324 37.1934 325.964C37.5977 326.006 38.1123 326.047 38.1123 326.047C38.1123 326.047 36.7523 326.877 37.1198 327.374C37.4873 327.872 38.2962 328.577 39.6563 326.296C42.5968 326.462 47.5109 324.964 46.7138 323.683Z'
                fill='black'
              />
            </g>
          </g>
          <g id='waves 4' clipPath='url(#clip3_282_3)'>
            <path
              id='waves-right_2'
              d='M216 287.5L217.018 288.878C218.089 290.327 220.305 290.166 221.154 288.576L221.365 288.182C222.138 286.735 224.171 286.627 225.094 287.984V287.984C225.934 289.219 227.739 289.264 228.64 288.072L228.976 287.627C229.927 286.369 231.848 286.468 232.665 287.818V287.818C233.458 289.131 235.309 289.269 236.289 288.09L236.818 287.454C237.878 286.178 239.836 286.178 240.897 287.454L241.49 288.168C242.414 289.28 244.171 289.097 244.846 287.82V287.82C245.541 286.506 247.365 286.359 248.261 287.545L248.542 287.917C249.5 289.185 251.42 289.137 252.314 287.823L252.418 287.67C253.289 286.389 255.178 286.389 256.05 287.67L256.167 287.843C257.012 289.085 258.859 289.036 259.636 287.75V287.75C260.414 286.464 262.261 286.415 263.106 287.657L263.273 287.903C264.14 289.178 266.003 289.224 266.932 287.994L267.126 287.738C268.117 286.426 270.12 286.53 270.97 287.937V287.937C271.755 289.236 273.555 289.446 274.618 288.363L275.13 287.842C276.507 286.44 278.853 286.791 279.759 288.536L280 289'
              stroke='black'
            />
          </g>
          <g id='sea-bottom'>
            <line id='Line 142' y1='332.5' x2='64' y2='332.5' stroke='black' />
            <line
              id='Line 143'
              x1='217'
              y1='332.5'
              x2='281'
              y2='332.5'
              stroke='black'
            />
          </g>
        </g>
        <g id='gas-lift-mandrels'>
          <path
            id='glm-1'
            d='M160 331.043L160 342.304L160 352.957L154 356L154 328L160 331.043Z'
            fill='#0000FF'
          />
          <path
            id='glm-2'
            d='M158.98 372.317L158.98 383.534L158.98 394.144L154.309 397.175L154.309 369.285L158.98 372.317Z'
            fill='#0000FF'
          />
          <path
            id='glm-3'
            d='M158.98 412.602L158.98 423.819L158.98 434.429L154.309 437.461L154.309 409.571L158.98 412.602Z'
            fill='#0000FF'
          />
          <path
            id='glm-4'
            d='M158.98 452.888L158.98 464.104L158.98 474.715L154.309 477.746L154.309 449.856L158.98 452.888Z'
            fill='#0000FF'
          />
          <path
            id='glm-5'
            d='M158.98 493.173L158.98 504.39L158.98 515L154.309 518.032L154.309 490.142L158.98 493.173Z'
            fill='#0000FF'
          />
        </g>
        <g id='sliding-side-doors'>
          <g id='sliding-side-door-1'>
            <rect
              id='Rectangle 36'
              x='122.5'
              y='292.5'
              width='35'
              height='19'
              stroke='#0000FF'
            />
            <rect
              id='Rectangle 38'
              x='152.861'
              y='292'
              width='5.13889'
              height='20'
              fill='#0000FF'
            />
            <rect
              id='Rectangle 39'
              x='122.028'
              y='292'
              width='5.13889'
              height='20'
              fill='#0000FF'
            />
          </g>
          <g id='sliding-side-door-2'>
            <rect
              id='Rectangle 36_2'
              x='123.473'
              y='408.5'
              width='34.027'
              height='18'
              stroke='#0000FF'
            />
            <rect
              id='Rectangle 37'
              x='122'
              y='408'
              width='3.69231'
              height='19'
              fill='#0000FF'
            />
            <rect
              id='Rectangle 38_2'
              x='154.108'
              y='408'
              width='3.89189'
              height='19'
              fill='#0000FF'
            />
          </g>
          <g id='sliding-side-door-3'>
            <rect
              id='Rectangle 36_3'
              x='123.473'
              y='472.5'
              width='34.027'
              height='18'
              stroke='#0000FF'
            />
            <rect
              id='Rectangle 37_2'
              x='122'
              y='472'
              width='3.69231'
              height='19'
              fill='#0000FF'
            />
            <rect
              id='Rectangle 38_3'
              x='154.108'
              y='472'
              width='3.89189'
              height='19'
              fill='#0000FF'
            />
          </g>
        </g>
        <g id='production-packer-2'>
          <g id='Group 22'>
            <rect
              id='Rectangle 27'
              x='154.224'
              y='603'
              width='15'
              height='15.4944'
              stroke='#0000FF'
            />
            <path
              id='Line 139_3'
              d='M154.489 603.501L168.808 618.292'
              stroke='#0000FF'
            />
            <line
              id='Line 140_3'
              y1='-0.5'
              x2='20.5864'
              y2='-0.5'
              transform='matrix(-0.695551 0.718476 0.695551 0.718476 169.224 603.775)'
              stroke='#0000FF'
            />
          </g>
          <g id='Group 22_2'>
            <rect
              id='Rectangle 27_2'
              x='110'
              y='603'
              width='15'
              height='15.4944'
              stroke='#0000FF'
            />
            <path
              id='Line 139_4'
              d='M110.265 603.501L124.584 618.292'
              stroke='#0000FF'
            />
            <line
              id='Line 140_4'
              y1='-0.5'
              x2='20.5864'
              y2='-0.5'
              transform='matrix(-0.695551 0.718476 0.695551 0.718476 125 603.775)'
              stroke='#0000FF'
            />
          </g>
        </g>
        <g id='production-packer-1'>
          <g id='Group 22_3'>
            <rect
              id='Rectangle 27_3'
              x='154.224'
              y='564'
              width='15'
              height='15.4944'
              stroke='#0000FF'
            />
            <path
              id='Line 139_5'
              d='M154.489 564.501L168.808 579.292'
              stroke='#0000FF'
            />
            <line
              id='Line 140_5'
              y1='-0.5'
              x2='20.5864'
              y2='-0.5'
              transform='matrix(-0.695551 0.718476 0.695551 0.718476 169.224 564.775)'
              stroke='#0000FF'
            />
          </g>
          <g id='Group 22_4'>
            <rect
              id='Rectangle 27_4'
              x='110'
              y='564'
              width='15'
              height='15.4944'
              stroke='#0000FF'
            />
            <path
              id='Line 139_6'
              d='M110.265 564.501L124.584 579.292'
              stroke='#0000FF'
            />
            <line
              id='Line 140_6'
              y1='-0.5'
              x2='20.5864'
              y2='-0.5'
              transform='matrix(-0.695551 0.718476 0.695551 0.718476 125 564.775)'
              stroke='#0000FF'
            />
          </g>
        </g>
        <g id='tubing-plug'>
          <path
            id='Rect'
            d='M128.8 523.788C128.8 522.131 130.143 520.788 131.8 520.788H148.972C150.629 520.788 151.972 522.131 151.972 523.788V557.143C151.972 558.561 150.979 559.785 149.592 560.078L141.097 561.87C140.692 561.956 140.273 561.957 139.868 561.872L131.19 560.072C129.798 559.783 128.8 558.556 128.8 557.134V523.788Z'
            fill='#0000FF'
          />
          <rect
            id='Rectangle 40'
            x='126'
            y='529.515'
            width='28'
            height='7.75757'
            fill='#0000FF'
          />
          <rect
            id='Rectangle 41'
            x='126'
            y='542.121'
            width='28'
            height='7.75757'
            fill='#0000FF'
          />
          <rect
            id='Rectangle 42'
            x='136.621'
            y='514'
            width='7.72414'
            height='6.78788'
            fill='#0000FF'
          />
        </g>
        <g id='downhole-fluid'>
          <ellipse
            id='Ellipse 13'
            cx='130.451'
            cy='701.671'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 21'
            cx='145.243'
            cy='701.671'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 14'
            cx='123.444'
            cy='695.473'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 15'
            cx='130.451'
            cy='690.05'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 16'
            cx='145.243'
            cy='685.402'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 23'
            cx='145.243'
            cy='674.555'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 17'
            cx='149.914'
            cy='693.149'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 22'
            cx='157.7'
            cy='698.572'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 18'
            cx='155.364'
            cy='681.528'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 19'
            cx='121.109'
            cy='679.979'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
          <ellipse
            id='Ellipse 20'
            cx='132.787'
            cy='676.88'
            rx='1.55706'
            ry='1.54944'
            fill='black'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_282_3'>
          <rect
            width='64'
            height='48'
            fill='white'
            transform='translate(0 263)'
          />
        </clipPath>
        <clipPath id='clip1_282_3'>
          <rect
            width='38.15'
            height='18'
            fill='white'
            transform='translate(8 270)'
          />
        </clipPath>
        <clipPath id='clip2_282_3'>
          <rect
            width='18.1667'
            height='10'
            fill='white'
            transform='matrix(-1 0 0 1 46.8 318)'
          />
        </clipPath>
        <clipPath id='clip3_282_3'>
          <rect
            width='64'
            height='48'
            fill='white'
            transform='translate(216 263)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
