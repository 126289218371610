import { useEffect, useContext, forwardRef, useState } from 'react';
import {
  InputGroup,
  Input,
  InputLeftAddon,
  InputRightAddon,
  Flex,
  Text,
  Divider,
  Select,
  Button,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import { MinusIcon } from '@chakra-ui/icons';
import { RxAngle } from 'react-icons/rx';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { BarrierContext } from '../context/BarrierContext';
import { TbBorderCorners } from 'react-icons/tb';
import { IoChevronDownSharp } from 'react-icons/io5';
import { arrowList } from '../data/arrowStyleList';
import { ColorPicker } from 'antd';

const DesignPanel = forwardRef((props, designPanelRef) => {
  const { anno, setAnno, accordionIndex, setAccordionIndex } =
    useContext(BarrierContext);

  const [bgColor, setBgColor] = useState('#ffffff');
  const [strokeColor, setStrokeColor] = useState('#000000');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      fontFamily: 'arial',
      fontSize: 12,
      fontWeight: 'normal',
      background: {
        color: 'transparent',
      },
      border: {
        style: 'solid',
      },
    },
  });

  useEffect(() => {
    setValue('position.x', anno ? anno?.position?.x : 0);
    setValue('position.y', anno ? anno?.position?.y : 0);
    setValue('size.width', anno ? anno?.size?.width : 0);
    setValue('size.height', anno ? anno?.size?.height : 0);
    setValue('rotation', anno ? anno?.rotation : 0);
    setValue('border.radius', anno ? anno?.border?.radius : 0);
    setValue('border.topLeftRadius', anno ? anno?.border?.topLeftRadius : 0);
    setValue('border.topRightRadius', anno ? anno?.border?.topRightRadius : 0);
    setValue(
      'border.bottomRightRadius',
      anno ? anno?.border?.bottomRightRadius : 0
    );
    setValue(
      'border.bottomLeftRadius',
      anno ? anno?.border?.bottomLeftRadius : 0
    );
    setValue('background.color', anno ? anno?.background?.color : '#000000');
    setValue('border.color', anno ? anno?.border?.color : '#000000');
    setValue('border.style', anno ? anno?.border?.style : 'solid');
    setValue('border.width', anno ? anno?.border?.width : 1);
    setValue(
      'fontFamily',
      anno && anno.type === 'text' ? anno?.fontFamily : 'arial'
    );
    setValue('fontSize', anno && anno.type === 'text' ? anno?.fontSize : 12);
    setValue(
      'fontWeight',
      anno && anno.type === 'text' ? anno?.fontWeight : 'normal'
    );

    if (anno?.background?.color === 'transparent') {
      setBgColor('#ffffff');
    } else {
      setBgColor(anno?.background?.color);
    }

    setStrokeColor(anno?.border?.color);
  }, [anno]);

  const switchArrow = (option) => {
    switch (option) {
      case 'triangle single':
        return (
          <svg
            width='56'
            height='6'
            viewBox='0 0 56 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='Arrow 9'
              d='M0 3L5 5.88675V0.113249L0 3ZM4.5 3.5H56V2.5H4.5V3.5Z'
              fill='black'
            />
          </svg>
        );
        break;
      case 'triangle double':
        return (
          <svg
            width='56'
            height='6'
            viewBox='0 0 56 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='Arrow 10'
              d='M0 3L5 5.88675V0.113249L0 3ZM56 3L51 0.113249V5.88675L56 3ZM4.5 3.5H51.5V2.5H4.5V3.5Z'
              fill='black'
            />
          </svg>
        );
        break;
      case 'line single':
        return (
          <svg
            width='57'
            height='8'
            viewBox='0 0 57 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='Arrow 7'
              d='M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H57V3.5H1V4.5Z'
              fill='black'
            />
          </svg>
        );
        break;
      case 'line double':
        return (
          <svg
            width='58'
            height='8'
            viewBox='0 0 58 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='Arrow 8'
              d='M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM57.3536 4.35355C57.5488 4.15829 57.5488 3.84171 57.3536 3.64645L54.1716 0.464466C53.9763 0.269204 53.6597 0.269204 53.4645 0.464466C53.2692 0.659728 53.2692 0.976311 53.4645 1.17157L56.2929 4L53.4645 6.82843C53.2692 7.02369 53.2692 7.34027 53.4645 7.53553C53.6597 7.7308 53.9763 7.7308 54.1716 7.53553L57.3536 4.35355ZM1 4.5H57V3.5H1V4.5Z'
              fill='black'
            />
          </svg>
        );
        break;
      default:
        return (
          <svg
            width='56'
            height='6'
            viewBox='0 0 56 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              id='Arrow 9'
              d='M0 3L5 5.88675V0.113249L0 3ZM4.5 3.5H56V2.5H4.5V3.5Z'
              fill='black'
            />
          </svg>
        );
    }
  };

  return (
    <div className='mt-[47px]'>
      <Accordion
        allowToggle
        index={accordionIndex}
        onChange={setAccordionIndex}
      >
        <AccordionItem>
          <AccordionButton padding={3} margin={0} borderTop={0}>
            <Box as='span' flex='1' textAlign='left'>
              Design Panel
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={2}>
            <form>
              <Flex flexDir='column' maxWidth='220px' ref={designPanelRef}>
                <Grid
                  templateColumns='repeat(2, 1fr)'
                  templateRows='repeat(4, 1fr)'
                  gap={0}
                  alignItems='center'
                >
                  <GridItem w='100%' colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Position
                    </Text>
                  </GridItem>

                  <GridItem w='100%'>
                    <InputGroup size='xs' w='100px' variant='ghost'>
                      <InputLeftAddon bgColor={'transparent'} w='28px'>
                        X
                      </InputLeftAddon>
                      <Input
                        {...register('position.x', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                position: {
                                  ...anno.position,
                                  x: +e.target.value,
                                },
                              });
                            }
                          },
                        })}
                      />
                    </InputGroup>
                  </GridItem>
                  <GridItem w='100%'>
                    <InputGroup size='xs' w='100px' variant='ghost'>
                      <InputLeftAddon bgColor={'transparent'} w='28px'>
                        Y
                      </InputLeftAddon>
                      <Input
                        {...register('position.y', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                position: {
                                  ...anno.position,
                                  y: +e.target.value,
                                },
                              });
                            }
                          },
                        })}
                      />
                    </InputGroup>
                  </GridItem>
                  <GridItem w='100%'>
                    <InputGroup size='xs' w='100px' variant='ghost'>
                      <InputLeftAddon bgColor={'transparent'} w='28px'>
                        W
                      </InputLeftAddon>
                      <Input
                        {...register('size.width', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                size: {
                                  ...anno.size,
                                  width: +e.target.value,
                                },
                              });
                            }
                          },
                        })}
                      />
                    </InputGroup>
                  </GridItem>
                  <GridItem w='100%'>
                    <InputGroup size='xs' w='100px' variant='ghost'>
                      <InputLeftAddon bgColor={'transparent'} w='28px'>
                        H
                      </InputLeftAddon>
                      <Input
                        {...register('size.height', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                size: {
                                  ...anno.size,
                                  height: +e.target.value,
                                },
                              });
                            }
                          },
                        })}
                      />
                    </InputGroup>
                  </GridItem>
                  <GridItem w='100%'>
                    <InputGroup size='xs' w='100px' variant='ghost'>
                      <InputLeftAddon bgColor={'transparent'} w='28px'>
                        <RxAngle className='w-[12px]' />
                      </InputLeftAddon>
                      <Input
                        {...register('rotation', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                rotation: +e.target.value,
                              });
                            }
                          },
                        })}
                      />
                    </InputGroup>
                  </GridItem>
                  <GridItem w='100%'>
                    <Flex align='center'>
                      <Popup
                        position='bottom center'
                        trigger={
                          <div className='mx-[6px]'>
                            <TbBorderCorners className='w-[12px]' />
                          </div>
                        }
                      >
                        <div className='w-[120px] border bg-white rounded'>
                          <Flex w='full'>
                            <InputGroup size='xs' w='100px' variant='ghost'>
                              <InputLeftAddon bgColor={'transparent'} w='24px'>
                                <svg
                                  width='17'
                                  height='17'
                                  viewBox='0 0 17 17'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M1 16.5V10.5C1 4.97715 5.47715 0.5 11 0.5H17'
                                    stroke='black'
                                  />
                                </svg>
                              </InputLeftAddon>
                              <Input
                                defaultValue={
                                  anno?.border?.topLeftRadius ||
                                  anno?.border?.radius
                                }
                                {...register('border.topLeftRadius', {
                                  onChange: (e) => {
                                    if (anno) {
                                      setAnno({
                                        ...anno,
                                        border: {
                                          ...anno.border,
                                          topLeftRadius: +e.target.value,
                                        },
                                      });
                                    }
                                  },
                                })}
                                textAlign='center'
                                ml={1}
                              />
                            </InputGroup>
                            <InputGroup size='xs' w='100px' variant='ghost'>
                              <Input
                                defaultValue={
                                  anno?.border?.topRightRadius ||
                                  anno?.border?.radius
                                }
                                textAlign='center'
                                {...register('border.topRightRadius', {
                                  onChange: (e) => {
                                    if (anno) {
                                      setAnno({
                                        ...anno,
                                        border: {
                                          ...anno.border,
                                          topRightRadius: +e.target.value,
                                        },
                                      });
                                    }
                                  },
                                })}
                              />
                              <InputRightAddon bgColor={'transparent'} w='24px'>
                                <svg
                                  width='17'
                                  height='17'
                                  viewBox='0 0 17 17'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M5.96046e-08 0.999999L6 1C11.5228 1 16 5.47715 16 11L16 17'
                                    stroke='black'
                                  />
                                </svg>
                              </InputRightAddon>
                            </InputGroup>
                          </Flex>
                          <Flex w='full'>
                            <InputGroup size='xs' w='100px' variant='ghost'>
                              <InputLeftAddon bgColor={'transparent'} w='24px'>
                                <svg
                                  width='17'
                                  height='17'
                                  viewBox='0 0 17 17'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M17 16L11 16C5.47715 16 1 11.5228 1 6L0.999999 -9.23872e-07'
                                    stroke='black'
                                  />
                                </svg>
                              </InputLeftAddon>
                              <Input
                                defaultValue={
                                  anno?.border?.bottomLeftRadius ||
                                  anno?.border?.radius
                                }
                                {...register('border.bottomLeftRadius', {
                                  onChange: (e) => {
                                    if (anno) {
                                      setAnno({
                                        ...anno,
                                        border: {
                                          ...anno.border,
                                          bottomLeftRadius: +e.target.value,
                                        },
                                      });
                                    }
                                  },
                                })}
                                textAlign='center'
                                ml={1}
                              />
                            </InputGroup>
                            <InputGroup size='xs' w='100px' variant='ghost'>
                              <Input
                                defaultValue={
                                  anno?.border?.bottomRightRadius ||
                                  anno?.border?.radius
                                }
                                textAlign='center'
                                {...register('border.bottomRightRadius', {
                                  onChange: (e) => {
                                    if (anno) {
                                      setAnno({
                                        ...anno,
                                        border: {
                                          ...anno.border,
                                          bottomRightRadius: +e.target.value,
                                        },
                                      });
                                    }
                                  },
                                })}
                              />
                              <InputRightAddon bgColor={'transparent'} w='24px'>
                                <svg
                                  width='17'
                                  height='17'
                                  viewBox='0 0 17 17'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M16 5.96046e-08L16 6C16 11.5228 11.5228 16 6 16L-9.23872e-07 16'
                                    stroke='black'
                                  />
                                </svg>
                              </InputRightAddon>
                            </InputGroup>
                          </Flex>
                        </div>
                      </Popup>
                      <Input
                        className='ml-[3px]'
                        size='xs'
                        variant='ghost'
                        {...register('border.radius', {
                          onChange: (e) => {
                            if (anno) {
                              setAnno({
                                ...anno,
                                border: {
                                  ...anno.border,
                                  radius: +e.target.value,
                                },
                              });
                            }
                          },
                        })}
                      />
                    </Flex>
                  </GridItem>
                </Grid>

                <Divider my={1} />

                <Grid
                  templateColumns='repeat(2, 1fr)'
                  templateRows='repeat(3, 1fr)'
                  gap={0}
                  alignItems='center'
                >
                  <GridItem>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Text
                    </Text>
                  </GridItem>
                  <GridItem w='100%' colSpan={2}>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='arial'
                      {...register('fontFamily', {
                        onChange: (e) =>
                          setAnno((prev) => ({
                            ...prev,
                            fontFamily: e.target.value,
                          })),
                      })}
                    >
                      <option value='arial'>Arial</option>
                      <option value='times new roman'>Times New Roman</option>
                    </Select>
                  </GridItem>
                  <GridItem w='100%'>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='normal'
                      {...register('fontWeight', {
                        onChange: (e) =>
                          setAnno((prev) => ({
                            ...prev,
                            fontWeight: e.target.value,
                          })),
                      })}
                    >
                      <option value='normal'>Normal</option>
                      <option value='bold'>Bold</option>
                    </Select>
                  </GridItem>
                  <GridItem w='100%'>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='12'
                      {...register('fontSize', {
                        onChange: (e) =>
                          setAnno((prev) => ({
                            ...prev,
                            fontSize: +e.target.value,
                          })),
                      })}
                    >
                      <option value='8'>8</option>
                      <option value='10'>10</option>
                      <option value='12'>12</option>
                      <option value='14'>14</option>
                      <option value='16'>16</option>
                      <option value='18'>18</option>
                      <option value='20'>20</option>
                      <option value='24'>24</option>
                      <option value='36'>36</option>
                    </Select>
                  </GridItem>
                </Grid>

                <Divider my={1} />

                <Grid
                  templateColumns='repeat(2, 1fr)'
                  templateRows='repeat(6, 1fr)'
                  gap={0}
                  alignItems='center'
                >
                  <GridItem colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Fill
                    </Text>
                  </GridItem>

                  <GridItem ml={1}>
                    <ColorPicker
                      disabledAlpha
                      size='small'
                      value={bgColor}
                      allowClear
                      onChange={(c) => {
                        if (anno) {
                          setAnno({
                            ...anno,
                            background: {
                              ...anno.background,
                              color: c.toHexString(),
                            },
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem marginRight={1}>
                    <div className='w-full flex justify-end '>
                      <span
                        className='bg-slate-100 w-6 h-6 flex justify-center items-center rounded'
                        onClick={(e) => {
                          if (anno) {
                            setAnno({
                              ...anno,
                              background: {
                                ...anno.background,
                                color: 'transparent',
                              },
                            });
                          }
                        }}
                      >
                        <MinusIcon fontSize='xs' />
                      </span>
                    </div>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Stroke
                    </Text>
                  </GridItem>
                  <GridItem ml={1}>
                    <ColorPicker
                      disabledAlpha
                      size='small'
                      value={strokeColor}
                      allowClear
                      onChange={(c) => {
                        if (anno) {
                          setAnno({
                            ...anno,
                            border: {
                              ...anno.border,
                              color: c.toHexString(),
                            },
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='1'
                      {...register('border.width', {
                        onChange: (e) => {
                          if (anno) {
                            setAnno({
                              ...anno,
                              border: {
                                ...anno.border,
                                width: +e.target.value,
                              },
                            });
                          }
                        },
                      })}
                    >
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                    </Select>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Pattern
                    </Text>
                  </GridItem>

                  <GridItem>
                    <InputGroup size='xs' w='full' variant='ghost'>
                      <InputLeftAddon>
                        <Popup
                          trigger={
                            <div className=' cursor-pointer w-[24px] h-[24px] border rounded flex justify-center items-center -ml-1'>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect width='16' height='16' fill='white' />
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M0 6.29289L6.29289 0H7.5C7.5 0.127961 7.45118 0.255922 7.35355 0.353553L0.353553 7.35355C0.255922 7.45118 0.127961 7.5 0 7.5V6.29289ZM0.707107 16H0V15.2929L15.2929 0H16V0.707107L0.707107 16ZM10.2071 16H9C9 15.872 9.04881 15.7441 9.14645 15.6464L15.6464 9.14645C15.7441 9.04881 15.872 9 16 9V10.2071L10.2071 16Z'
                                  fill='black'
                                />
                              </svg>
                            </div>
                          }
                          position='bottom center'
                        >
                          <div className='w-[100px] bg-slate-50 flex-col'>
                            <div
                              className='p-1 flex justify-center cursor-pointer'
                              onClick={() => {
                                if (anno) {
                                  setAnno({
                                    ...anno,
                                    background: {
                                      pattern: 'crossline',
                                    },
                                  });
                                }
                              }}
                            >
                              <svg
                                width='81'
                                height='18'
                                viewBox='0 0 81 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect width='81' height='18' fill='white' />
                                <path
                                  d='M0.646484 10.3145L9.64648 1.31445'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M9.64648 17.3145L25.6465 1.31445'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M23.6465 17.3145L39.6465 1.31445'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M37.6465 17.3145L53.6465 1.31445'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M51.6465 17.3145L67.6465 1.31445'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M66.3147 16.6465L80 3'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                              </svg>
                            </div>
                            <div
                              className='p-1 flex justify-center cursor-pointer'
                              onClick={() => {
                                if (anno) {
                                  setAnno({
                                    ...anno,
                                    background: {
                                      pattern: 'dash',
                                    },
                                  });
                                }
                              }}
                            >
                              <svg
                                width='83'
                                height='18'
                                viewBox='0 0 83 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='1'
                                  width='81'
                                  height='18'
                                  fill='white'
                                />
                                <path
                                  d='M6 5H16'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M1 14L10 14'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M26 5H36'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M20 14H30'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M46 5H56'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M40 14H50'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M66 5H76'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M60 14H70'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                                <path
                                  d='M80 14H82'
                                  stroke='black'
                                  strokeLinecap='round'
                                />
                              </svg>
                            </div>
                            <div
                              className='p-1 flex justify-center cursor-pointer'
                              onClick={() => {
                                if (anno) {
                                  setAnno({
                                    ...anno,
                                    background: {
                                      pattern: 'dot',
                                    },
                                  });
                                }
                              }}
                            >
                              <svg
                                width='81'
                                height='18'
                                viewBox='0 0 81 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect width='81' height='18' fill='white' />
                                <circle cx='8' cy='5' r='2' fill='black' />
                                <circle cx='3' cy='13' r='2' fill='black' />
                                <circle cx='13' cy='13' r='2' fill='black' />
                                <circle cx='74' cy='5' r='2' fill='black' />
                                <circle cx='79' cy='13' r='2' fill='black' />
                                <circle cx='41' cy='5' r='2' fill='black' />
                                <circle cx='46' cy='13' r='2' fill='black' />
                                <circle cx='19' cy='5' r='2' fill='black' />
                                <circle cx='24' cy='13' r='2' fill='black' />
                                <circle cx='52' cy='5' r='2' fill='black' />
                                <circle cx='57' cy='13' r='2' fill='black' />
                                <circle cx='63' cy='5' r='2' fill='black' />
                                <circle cx='68' cy='13' r='2' fill='black' />
                                <circle cx='30' cy='5' r='2' fill='black' />
                                <circle cx='35' cy='13' r='2' fill='black' />
                              </svg>
                            </div>
                          </div>
                        </Popup>
                      </InputLeftAddon>
                    </InputGroup>
                  </GridItem>
                  <GridItem marginRight={1}>
                    <div className='w-full flex justify-end '>
                      <span
                        className='bg-slate-100 w-6 h-6 flex justify-center items-center rounded'
                        onClick={() => {
                          if (anno) {
                            setAnno({
                              ...anno,
                              background: {
                                pattern: null,
                              },
                            });
                          }
                        }}
                      >
                        <MinusIcon fontSize='xs' />
                      </span>
                    </div>
                  </GridItem>
                </Grid>

                <Divider my={1} />
                <Grid
                  templateColumns='repeat(2, 1fr)'
                  templateRows='repeat(4, 1fr)'
                  gap={0}
                  alignItems='center'
                >
                  <GridItem colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Line Style
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='solid'
                      {...register('border.style', {
                        onChange: (e) => {
                          if (anno) {
                            setAnno({
                              ...anno,
                              border: {
                                ...anno.border,
                                style: e.target.value,
                              },
                            });
                          }
                        },
                      })}
                    >
                      <option value='solid'>Solid</option>
                      <option value='dashed'>Dashed</option>
                    </Select>
                  </GridItem>
                  <GridItem>
                    <Select
                      size='xs'
                      variant='ghost'
                      defaultValue='1'
                      {...register('border.width', {
                        onChange: (e) => {
                          if (anno) {
                            setAnno({
                              ...anno,
                              border: {
                                ...anno.border,
                                width: e.target.value,
                              },
                            });
                          }
                        },
                      })}
                    >
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                    </Select>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text fontSize='12px' px={2} fontWeight='semibold'>
                      Arrow Style
                    </Text>
                  </GridItem>
                  <GridItem mx={2}>
                    <Popup
                      trigger={
                        <div className='flex w-full items-center justify-between'>
                          {switchArrow(anno?.arrowStyle)}
                          <IoChevronDownSharp className='w-[14px]' />
                        </div>
                      }
                    >
                      <div className='bg-white border rounded-md space-y-1 w-[80px]'>
                        {arrowList?.map((item) => (
                          <div
                            key={item.id}
                            className='h-4 hover:bg-[#EDF2F7] flex items-center justify-center px-3 py-1'
                            onClick={() => {
                              if (anno) {
                                setAnno({
                                  ...anno,
                                  arrowStyle: item?.arrowStyle,
                                });
                              }
                            }}
                          >
                            {item.svg}
                          </div>
                        ))}
                      </div>
                    </Popup>
                  </GridItem>
                </Grid>
              </Flex>
            </form>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
});

export default DesignPanel;
