import { createContext, useState, useEffect, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import axios from 'axios';
export const BarrierContext = createContext();

export const BarrierProvider = ({ handleEvent, children }) => {
  const toast = useToast();
  const [allConfigs, setAllConfigs] = useState({data: [], size: 0, current: 1});
  const [config, setConfig] = useState(null);
  const [configSelected, setConfigSelected] = useState(false);
  const [well, setWell] = useState(null);
  const [update, setUpdate] = useState(false);
  const [selectedWell, setSelectedWell] = useState('');
  const [schematic, setSchematic] = useState('');
  const [anno, setAnno] = useState(null);
  const [component, setComponent] = useState(null);
  const [cdft, setCdft] = useState(false);
  const [cdftValue, setCdftValue] = useState([]);
  const [selectedAnno, setSelectedAnno] = useState(null);
  const [accordionIndex, setAccordionIndex] = useState(null);
  const [element, setElement] = useState(null);
  const [isApplication, setIsApplication] = useState(false);
  const [configHistory, setConfigHistory] = useState([]);

  // console.log('🚀 ~ BarrierProvider ~ config:', config);

  const handleGetAllConfigs = (payload = {}) => {
    handleEvent("getAllConfig", payload).then((res) => {
      setIsApplication(res.isApplication);
      setAllConfigs(res.allConfig);
      setSchematic(res.schematic);
      if(res.selectedConfig) {
        setConfig(res.selectedConfig);
        setComponent('diagram');
      }
    });
  };

  const handleSave = () => {
    const eventName = config.id ? "update" : "create";
    handleEvent(eventName, config).then(() => {
      setUpdate()
    });
  };

  const handleDelete = (id) => {
    handleEvent("delete", {id}).then(() => {
      setUpdate(true)
    })
    .catch((e) => console.error(e));
  };

  const handleDuplicate = (item) => {
    const dataObj = {
      ...item,
      id: nanoid(),
      name: `${item.name} copy`,
    };
      
    handleEvent("create", dataObj).then(() => {
      setUpdate()
    })
    .catch((e) => console.error(e));
  };

  const handleAssign = (item) => {
    if(!item?.id) {
      return toast({
        title: `Config not selected`,
        status: 'error',
        position: 'top-right',
        duration: 1500,
        isClosable: true,
        variant: 'subtle',
      })
      
    }
    handleEvent("assign", {id: item.id}).then(() => {
      setUpdate(true)
    })
    .catch((e) => console.error(e));
  };

  const handleStatus = (status) => {
    const payload = {
      status: status,
      wellSchematicId: config.id
    }
    handleEvent("status", payload).then(() => {
      setUpdate(true)
    })
    .catch((e) => console.error(e));
  };

  const handleResetBarriers = () => {
    const updated = config?.elements?.map((item) => ({
      ...item,
      barrier: 'none',
      status: 'none',
    }));

    const updatedAdditional = config?.additionalElements?.map((item) => ({
      ...item,
      barrier: 'none',
      status: 'none',
    }));

    setConfig((prev) => ({
      ...prev,
      elements: updated,
      additionalElements: updatedAdditional,
    }));
    setUpdate(true);
  };

  const handleConfigHistory = async () => {
    
    handleEvent("getConfigurationHistory").then((response) => {
        setConfigHistory(response);
        setComponent('config history');
    })
    .catch((e) => console.error(e));
  };

  const handleCdft = async (status = true) => {
    if(!status) return; 
    handleEvent("getCdft").then((response) => {
      setCdftValue(response);
    })
    .catch((e) => console.error(e));
  }


  useEffect(() => {
    let subscribed = true;
    if (subscribed) {
      handleGetAllConfigs();
      handleCdft();
      setUpdate(false);
    }
    return () => {
      subscribed = false;
    };
  }, [update]);

  useEffect(() => {
    const updated = config?.annotations?.map((item) =>
      item?.id === anno?.id ? { ...anno } : item
    );

    setConfig((prev) => ({
      ...prev,
      annotations: updated,
    }));
  }, [anno]);

  useEffect(() => {
    const updated = config?.elements?.map((item) =>
      item?.id === element?.id ? { ...element } : item
    );
    setConfig((prev) => ({
      ...prev,
      elements: updated,
    }));
  }, [element]);

  useEffect(() => {
    const updated = config?.additionalElements?.map((item) =>
      item?.id === element?.id ? { ...element } : item
    );
    setConfig((prev) => ({
      ...prev,
      additionalElements: updated,
    }));
  }, [element]);

  const setCdftFill = (code) => {
    let valve = cdftValue.filter((row) => {
      return row.shortName == code;
    })

    if(!(valve.length == 1)) return '#FFF';

    try {
      valve = valve[0];
      switch(valve.status.toLowerCase()) {
        case 'good': return '#22c55e';
        case 'degraded': return '#fde047';
        case 'fail': return '#FF0000';
        default: return '#FFFFFF';
      }
    } catch (e) {}

    return '#FFF';
  }

  const setStroke = (name) => {
    let el = config?.elements?.find((item) => item?.name === name);

    if (el.barrier === 'none' || el.quantity === 0) {
      return '#000000';
    } else if (el.barrier === 'primary') {
      return '#0000FF';
    } else if (el.barrier === 'secondary') {
      return '#FF0000';
    } else {
      return '#000000';
    }
  };

  return (
    <BarrierContext.Provider
      value={{
        allConfigs,
        setAllConfigs,
        config,
        setConfig,
        selectedWell,
        setSelectedWell,
        handleStatus,
        handleResetBarriers,
        handleDuplicate,
        handleDelete,
        handleAssign,
        handleSave,
        handleConfigHistory,
        handleGetAllConfigs,
        isApplication,
        setIsApplication,
        component,
        setComponent,
        update,
        setUpdate,
        well,
        setWell,
        cdft,
        setCdft,
        anno,
        setAnno,
        selectedAnno,
        setSelectedAnno,
        accordionIndex,
        setAccordionIndex,
        element,
        setElement,
        setCdftFill,
        setStroke,
        configSelected, 
        setConfigSelected,
        configHistory,
        setConfigHistory,
        schematic
      }}
    >
      {children}
    </BarrierContext.Provider>
  );
};
